<template>
  <div>
    <div class="panel-content">
      <div class="detail-content">
        <div class="detail-form">
          <div class="title">会议信息</div>
          <el-form label-position="left" ref="form" :model="form" label-width="160px">
            <el-form-item label="会议主题">
              <el-input v-model="form.subject" placeholder="请输入会议主题"></el-input>
            </el-form-item>
            <el-form-item label="开始时间">
              <div class="begin-date">
                <el-date-picker v-model="form.startDate" :clearable="false" style="margin-right: 20px" format="YYYY-MM-DD" value-format="YYYY-MM-DD" type="date" placeholder="选择日期"></el-date-picker>

                <el-time-picker
                    :clearable="false"
                    value-format="HH:mm"
                    format="HH:mm"
                    v-model="form.startTime"
                    placeholder="选择时间"
                />
              </div>
            </el-form-item>

            <el-form-item label="时长">
              <div class="begin-date">
                <el-select v-model="form.durationHour" style="margin-right: 20px" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>

                <el-select v-model="form.durationMinute" placeholder="请选择">
                  <el-option v-for="item in optionsMinute" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="">
              <div style="display: flex;flex-direction: column">
                <el-checkbox @change="changeType">周期会议</el-checkbox>
                <el-form label-position="left" label-width="160px" :model="form" v-show="isCheckbox">
                  <el-form-item class="el-form-item" label="会议周期">
                    <el-select class="select-class" placeholder="请选择" v-model="form.recurrenceType">
                      <el-option v-for="item in cycleList" :key="item.value" :label="item.label" :value="item.value"/>
                    </el-select>
                  </el-form-item>

                  <el-form-item v-if="[0,1].includes(form.recurrenceType)" class="el-form-item" label="周期间隔">
                    <div class="cycle">
                      <el-input v-model="form.interval" style="width:150px !important;display: block !important;margin-right: 16px;" placeholder="请输入周期" type="number"></el-input>
                      <el-checkbox-group v-model="form.dayOfWeeks" v-if="form.recurrenceType == 1">
                        <el-checkbox-button v-for="date in dateOptions" :label="date" :key="date">{{date}}</el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </el-form-item>

                  <el-form-item class="el-form-item" label="结束时间">
                    <el-date-picker v-model="form.rangeEndDate" :clearable="false"  value-format="YYYY-MM-DD" style="width:300px !important;display: block !important" type="date" placeholder="选择结束日期"></el-date-picker>
                  </el-form-item>
                </el-form>
              </div>
            </el-form-item>

            <el-form-item label="会议密码">
              <el-input style="width:300px !important;display: block !important" type="password" placeholder="请输入密码" v-model="form.conferencePassword" maxlength="6" show-word-limit></el-input>
            </el-form-item>

            <el-form-item label="备注">
              <el-input type="textarea" placeholder="请输入备注信息" v-model="form.remark"  maxlength="3000" show-word-limit></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
              <el-button>取消</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="meeting-members">
          <div class="title">参会成员</div>
          <div class="selected-footer">
            <el-button @click="joinAdd" style="width: 100%;border: 1px solid #dcdfe6 !important">添加参会成员</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog width="758px" append-to-body v-model="isShow" title="添加成员" :close-on-click-modal="false">
      <div class="dialog-box">
        <div class="tree-box">
          <el-tree node-key="dataId" :default-checked-keys="defaultKeys" :default-expanded-keys="['0']" :data="allTree" show-checkbox :props="defaultProps" check-strictly @check="handleCheck" />
        </div>
        <div class="selected-box">
          <div class="data-box">
            <div class="data-box__header">
              <span class="selected">已选({{checkedNodes.length}}/500)</span>
            </div>

            <div class="clusterize-scroll">
              <div v-for="(item, index) in checkedNodes" :key="index">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="chooseUsers">确定</el-button>
          </span>
        </template>
    </el-dialog>
  </div>
</template>

<script>
import { devicesTree, contactsTree, usersTree, activeUser, addMeeting, getToken } from "@/api/videomeeting/addmeeting";
import { ElMessage } from 'element-plus';
export default {
  data(){
    return{
      defaultKeys:[],
      checkedNodes:[],
      isShow: false,
      isCheckbox: false,
      dateOptions:[
          '日','一','二','三','四','五','六'
      ],
      cycleList:[
        {
          value: 0,
          label: '按天循环',
        },
        {
          value: 1,
          label: '按周循环',
        },
        {
          value: 2,
          label: '按月循环',
        },
      ],
      optionsMinute:[
        {
          value: 0,
          label: 0,
        },
        {
          value: 15,
          label: 15,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 45,
          label: 45,
        },
      ],
      options:[],
      form:{
        conferencePassword:"",
        dayOfWeeks:[],
        recurrenceType:"", //会议周期
        interval:"", //时间间隔
        durationHour: 1, //时长
        durationMinute: 0,  //分钟
        subject: "管理员的会议", //会议主题
        startDate:"",
        startTime:"",
      },
      allTree:[
        {
          name:"成员列表",
          children:[],
          disabled: true,
          dataId: "0"
        }
      ],
      defaultProps:{
        children: 'children',
        label: 'name',
        disabled: "disabled"
      }
    }
  },

  watch:{
    "form.recurrenceType"(){
      this.form.rangeEndDate = "";
      this.form.interval = 1;
      this.form.dayOfWeeks = [];
    },
    "form.durationHour"(n){
      if(n == 0){
        this.optionsMinute = [
          {
            value: 30,
            label: 30,
          },
          {
            value: 45,
            label: 45,
          },
        ];
        this.form.durationMinute = 30;
      }else{
        this.optionsMinute = [
          {
            value: 0,
            label: 0,
          },
          {
            value: 15,
            label: 15,
          },
          {
            value: 30,
            label: 30,
          },
          {
            value: 45,
            label: 45,
          },
        ]
        this.form.durationMinute = 0;
      }
    },
  },

  methods:{
    changeType(v){
      this.isCheckbox = v;
      this.form.rangeEndDate = "";
      if(v) {
        this.form.recurrenceType = 0;
        this.form.interval = 1;
      } else {
        this.form.recurrenceType = "";
        this.form.interval = "";
      }
    },
    chooseUsers(){
      let [a] = this.defaultKeys;
      this.form.participants = this.checkedNodes.map(item=>{
        return{
          id: item.dataId,
          role: item.dataId == a ? 0 : 2,
          type: 0,
          name: item.name
        }
      });
      this.isShow = false;
    },

    clearEmptyPro(obj) {
      let curVal = {};
      let toString = Object.prototype.toString;
      for (let key in obj) {
        let value = obj[key];
        let type = toString.call(value);
        if (value || type === "[object Number]" || type === "[object Boolean]") {
          switch (type) {
            case "[object Object]":
              if (Object.keys(value).length > 0) {
                curVal[key] = value;
              }
              break;
            case "[object Array]":
              if (value.length > 0) {
                curVal[key] = value;
              }
              break;
            default:
              curVal[key] = value;
              break;
          }
        }
      }
      return curVal;
    },

    onSubmit(){
      if(!this.form.subject) return ElMessage({message: '请输入 会议主题', type: 'warning'});
      if(!this.form.startDate) return ElMessage({message: '请选择 开始时间', type: 'warning'});
      if(!this.form.startTime) return ElMessage({message: '请输入 选择时间', type: 'warning'});
      if(this.form.recurrenceType === 0 && !this.form.interval) return ElMessage({message: '请输入 周期间隔', type: 'warning'});
      if(this.form.recurrenceType === 1 && !this.form.interval) return ElMessage({message: '请输入 周期间隔', type: 'warning'});
      if(this.form.recurrenceType === 1 && this.form.dayOfWeeks.length < 1) return ElMessage({message: '请选择 周几', type: 'warning'});
      if(this.isCheckbox && !this.form.rangeEndDate) return ElMessage({message: '请选择 结束时间', type: 'warning'});
      if(this.form.conferencePassword.length > 0 && this.form.conferencePassword.length != 6) return ElMessage({message: '会议密码为空或6位', type: 'warning'});
      addMeeting({
        ...this.clearEmptyPro(this.form),
        conferencePassword: this.form.conferencePassword || ""
      }).then(res=>{
        if(res.data.code.status == 1){
          ElMessage({message: res.data.code.message, type: 'success'});
          this.form.subject = '管理员的会议';
          this.form.durationMinute = 0;
          this.form.durationHour = 1;
          this.form.interval = '';
          this.form.recurrenceType = '';
          this.form.dayOfWeeks = [];
          this.checkedNodes = [];
          this.form.participants = [];
          this.form.conferencePassword = "";
          this.form.remark = "";
          this.form.startDate = "";
          this.form.startTime = "";
          this.changeType(false);
        }
      })

    },

    getObjById(list, dataId) {
      for(let i in list){
        let item = list[i];
        if(item.dataId === dataId) return item;
        else {
          if(item.children) {
            let obj = this.getObjById(item.children, dataId);
            if(obj) return obj
          }
        }
      }
    },

    joinAdd(){
      activeUser().then(res=>{
        this.isShow = true;
        this.defaultKeys = [res.data.data];
        this.form.participants = this.checkedNodes.map(item=>{
          return{
            id: item.dataId,
            role: item.dataId == res.data.data ? 0 : 2,
            type: 0,
            name: item.name
          }
        });
      })
    },

    handleCheck(tree, checked){
      this.checkedNodes = checked.checkedNodes;
    },

    devicesList(){
      devicesTree().then(res=>{
        let data = this.setDisabled(JSON.parse(res.data.data));
        this.treeData(data)[0].name = "硬件终端列表";
        this.allTree.at(-1).children.push(...this.treeData(data))
      })
    },

    contactsList(){
      contactsTree().then(res=>{
        let data = this.setDisabled(JSON.parse(res.data.data));
        this.treeData(data)[0].name = "外部联系人列表";
        this.allTree.at(-1).children.push(...this.treeData(data))
      })
    },

    // getIndexArray(arr, name){
    //   let index = [];
    //   for(let i = 0,len=arr.length;i<len;i++){
    //     if(arr[i].name == name){
    //       index.push(i)
    //       break;
    //     }else{
    //       if(arr[i].child){
    //         let _i = this.getIndexArray(arr[i].child, name);
    //       }
    //     }
    //   }
    //   return index
    // },

    usersList(){
      usersTree().then(res=>{
        let data = this.setDisabled(JSON.parse(res.data.data));

        activeUser().then(r=>{
          if(this.checkedNodes.filter(item=>item.dataId == r.data.data).length < 1){
            this.checkedNodes.push(this.getObjById(this.allTree, r.data.data))
          }
          this.form.participants = this.checkedNodes.map(item=>{
            return{
              id: item.dataId,
              role: item.dataId == res.data.data ? 0 : 2,
              type: 0,
              name: item.name
            }
          });

          data[data.findIndex(item=>item.dataId == r.data.data)].disabled = true;
        })

        this.treeData(data)[0].name = "企业成员列表";
        this.allTree.at(-1).children.push(...this.treeData(data))
      })
    },

    setDisabled(data){
      let arr = data.map(item=>{
        if(item.type === 0) {
          return {
            ...item,
            disabled: true
          }
        }else {
          return item
        }
      })
      return arr;
    },

    treeData(data, pid = null){
      let list = []
      data.forEach((obj) => {
        if (obj.parentId === pid) {
          let children = this.treeData(data, obj.id)
          if (children.length) {
            obj.children = children
          }
          list.push(obj)
        }
      })
      return list
    }
  },

  mounted() {
    for(let i=0,len=24;i<len;i++){
      this.options[i] = {
        value: i,
        label: i,
      }
    }
  },

  created() {
    getToken().then(()=>{
      this.devicesList();
      this.usersList();
      this.contactsList();
    })
  },
}
</script>

<style scoped lang="scss">
.dialog-box{
  display: flex;
  height: 100%;
  .tree-box{
    width: calc(50% - 24px);
  }
  .selected-box{
    height: 100%;
    width: calc(50% + 24px);
    display: flex;
    .data-box{
      border-left: 1px solid #59B7A4;
      border-radius: 2px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      min-width: 300px;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      margin-left: 24px;
      width: calc(100% - 25px);
      .data-box__header{
        background: #0a2b28;
        padding-bottom: 14px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-left: 24px;
        .selected{
          margin-top: 8px;
          color: #59B7A4;
          height: 20px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .clusterize-scroll{
        margin-left: 24px;
        overflow: auto;
        max-height: calc(100% - 55px);
      }
    }
  }
}
.title{
  text-align: left;
  height: 32px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 2px;
}

.cycle{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.el-form-item{
  display: flex;
  margin-bottom: 18px;
}
.select-class{
  width:300px !important;
  display: block !important;
}
.panel-content{
  max-height: 100%;
  padding: 0 24px;
  .detail-content{
    height: 100%;
    display: flex;
    .detail-form{
      width: 60%;
      margin-right: 17px;
      .begin-date{
        display: flex;
      }
    }
    .meeting-members{
      margin-left: 100px;
      width: calc(40% - 100px);
      border-radius: 4px;
      position: relative;
      .selected-footer{
        width: 100%;
        padding: 12px 0 16px;
        text-align: center;
      }
    }
  }
}
</style>