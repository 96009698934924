import axios from "@/api/axios"; // 引用axios

// 查询硬件终端树
export const devicesTree = (params) => {
    return axios({
        url: "/api/yealink/devices/tree",
        method: "get",
        params: params,
    });
};

// 查询外部联系人树
export const contactsTree = (params) => {
    return axios({
        url: "/api/yealink/external-contacts/tree",
        method: "get",
        params: params,
    });
};

// 查询用户树
export const usersTree = (params) => {
    return axios({
        url: "/api/yealink/users/tree",
        method: "get",
        params: params,
    });
};


// 查询当前默认账号
export const activeUser = () => {
    return axios({
        url: "/api/yealink/activeUser",
        method: "get",
    });
};


// 新增会议
export const addMeeting = (data) => {
    return axios({
        url: "/api/yealink/addMeeting",
        method: "post",
        data
    });
};

export const getToken = () => {
    return axios({
        url: "/api/yealink/token",
        method: "get",
    });
};
